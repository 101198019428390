/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link, Box, Image, Flex } from 'rebass';

import { P, H3, H4 } from '../components/Text';
import { Container } from '../components/Container';
import { AboutLayout } from '../layouts/AboutLayout';

import { useTranslation } from '../i18n/TranslationContext';

interface TeamMemberProps {
  name: string;
  role: string;
  pictureUrl: string;
  active?: string;
  linkedInUrl?: string;
  twitterUrl?: string;
}
const TeamMember: React.FC<TeamMemberProps> = ({
  name,
  role,
  pictureUrl,
  active,
  linkedInUrl,
  twitterUrl,
}) => {
  return (
    <Box>
      <Image src={pictureUrl} sx={{ width: ['100%'], borderRadius: '4px' }} />
      <H4>{name}</H4>
      <P>{role}</P>
      <Flex justifyContent="space-between">
        {linkedInUrl && <Link href={linkedInUrl}>LinkedIn</Link>}
        {twitterUrl && <Link href={twitterUrl}>Twitter</Link>}
      </Flex>
    </Box>
  );
};

interface AboutTeamPageProps {
  pageContext: {
    teamMembers: {
      name: string;
      role: string;
      twitter: string;
      linkedin: string;
      active: string;
      images?: {
        src: string;
        srcSet: string;
      };
    }[];
  };
}
const AboutTeamPage: React.FC<AboutTeamPageProps> = ({
  pageContext: { teamMembers },
}) => {
  const t = useTranslation();

  return (
    <AboutLayout>
      <Container>
        <H3>{t('about_team_h2')}</H3>
        <P>{t('about_team_text')}</P>
        <Box
          sx={{
            display: 'grid',
            rowGap: [3, 4],
            columnGap: [2, 4],
            gridTemplateColumns: [
              'repeat(auto-fill, minmax(135px, 1fr))',
              'repeat(4, 1fr)',
            ],
          }}
        >
          {teamMembers.map(teamMember => {
            return (
              teamMember.active === 'TRUE' && (
                <TeamMember
                  key={teamMember.name}
                  name={teamMember.name}
                  role={teamMember.role}
                  linkedInUrl={teamMember.linkedin}
                  twitterUrl={teamMember.twitter}
                  pictureUrl={
                    teamMember.images
                      ? teamMember.images.src
                      : 'http://placekitten.com/200/200'
                  }
                />
              )
            );
          })}
        </Box>
      </Container>
    </AboutLayout>
  );
};

export default AboutTeamPage;
